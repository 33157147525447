// Bootstrap-js
import "bootstrap";
// import "vegas";
// Slick-js
// import "slick-carousel";
import "./plugins/jquery.bootstrap-autohidingnavbar.min.js"; // スクロールアップナビ
// 実行スクリプト
import "./myscript";
import "./plugins/picturefill.min";
// scss
import "./index.scss";
